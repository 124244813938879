/**
 * Created by hoa on 14/11/2017.
 */
define(['app', '$window'], (app, $window) => {

  const setLeo = () => {
    const component = {};

    const _config = {
      outerContainer: '.setLeo_dayNumberOuterContainer',
      outerContainerShow: 'setLeo_dayNumberOuterContainer-show',
      container: '.setLeo_dayNumberContainer',
      dayNumber: '.setLeo_dayNumber',
      presentDay: '.setLeo_dayPresent',
      presentDayClass: 'setLeo_dayPresent',
      daySelectNumber: '.setLeo_daySliderCurrentDay',
      opened: {
        daySlider: 'setLeo_daySlider-open',
        daySliderSelector: '.setLeo_daySlider-open',
        setLeoShow: 'setLeo_block-present',
        setLeoShowSelector: '.setLeo_block-present'
      }
    };

    const _init = (element) => {
      const isMobile = $window.innerWidth < 500;

      component.element = element;

      if (isMobile) {
        component.getElements(element);
      }

      return component;
    };

    const _getElements = (el) => {
      const dayOuterContainer = el.querySelector(component.config.outerContainer);
      const dayContainer = el.querySelector(component.config.container);
      const dayNumber = el.querySelectorAll(component.config.dayNumber);
      const dayNumberWidth = 54;
      const presentDay = el.querySelector(component.config.presentDay);
      const presentDayAttr = app.element.getAttribute('data-current', presentDay);
      const parsedPresentDay = parseInt(presentDayAttr);

      const scrollLeftCalc = dayNumberWidth * (parsedPresentDay - 0.5) - 25;

      component.attachEvent(dayNumber);
      component.viewCurrentDay(dayOuterContainer, dayContainer, scrollLeftCalc, dayNumberWidth, dayNumber.length);
    };

    const _viewCurrentDay = (outerContainer, dayContainer, position, dayNumberWidth, dayNumber) => {
      const windowViewport = $window.outerWidth / 2;
      const containerWidth = dayNumberWidth * (dayNumber - 0.5) + windowViewport;
      dayContainer.style.width = `${containerWidth}px`;
      app.element.addClass(component.config.outerContainerShow, outerContainer);
      outerContainer.scrollLeft = position;
    };

    const _attachEvent = (days) => {
      [...days].forEach((el) => {
        el.addEventListener('click', component.selectOffer, false);
      });
    };

    const _selectOffer = (e) => {
      const currentTarget = e.currentTarget;
      const getCurrentCard = app.element.getAttribute('data-current', currentTarget);
      const showSetLeoBlock = component.element.querySelector(`.setLeo_block-${getCurrentCard}`);
      const presentDay = component.element.querySelector(component.config.presentDay);
      const activeSetLeoBlock = component.element.querySelector(component.config.opened.setLeoShowSelector);

      if (presentDay) {
        app.element.removeClass(component.config.presentDayClass, presentDay);
      }

      if (activeSetLeoBlock) {
        app.element.removeClass(component.config.opened.setLeoShow, activeSetLeoBlock);
      }

      app.element.addClass(component.config.opened.setLeoShow, showSetLeoBlock);
      app.element.addClass(component.config.presentDayClass, currentTarget);
    };

    component.config = _config;
    component.init = _init;
    component.getElements = _getElements;
    component.attachEvent = _attachEvent;
    component.selectOffer = _selectOffer;
    component.viewCurrentDay = _viewCurrentDay;

    return component;
  };

  return setLeo;
});
